import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import { InstaFeed } from "../components/insta-feed"
import { Signup } from "../components/signup"

const IndexPage = () => (
  <Layout>
    <SEO title="HaroldRichter.art" />
    <Signup></Signup>
    {/* <InstaFeed></InstaFeed> */}
  </Layout>
)

export default IndexPage
