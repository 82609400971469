import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import '../styles/signup.css'

export class Signup extends React.Component {
    state = {
        email: null,
        isSubmitted: false,
        allFields: {
            'group[68747][1]': '1',
            'group[68747][2]': '2'
        }
    }

    _handleSubmit = async (e) => {
        e.preventDefault();
        await addToMailchimp(this.state.email, this.state.allFields)
        this.setState({isSubmitted: true});
    }

    _handleChange = (e) => {
        console.log({
            [`${e.target.name}`]: e.target.value,
        });
        this.setState({
            [`${e.target.name}`]: e.target.value,
        });
    }

    render () {
        if (this.state.isSubmitted)
            return <h3>Thanks!</h3>
        return (
            <div className="signup-form">
                <p>Receive a note when new comics are released!</p>
                <div className="wrapper">
                    <form onSubmit={this._handleSubmit}>
                        <input
                            className="email-input"
                            type="email"
                            onChange={this._handleChange}
                            placeholder="Enter your email address"
                            name="email"
                        />
                        <input className="submit-btn" type="submit" value="Subscribe"/>
                    </form>
                </div>
                <p>Browse the available comics for sale <a href="https://gumroad.com/haroldrichter" target="_blank" style={{'color': '#CD5B5B'}}>here</a></p>
            </div>
        )
    }
}
